@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer components {

  .markdown {
    @apply text-lg ;
  }

  .markdown > p {
    @apply font-normal
  }
  
  .markdown p,
  .markdown ul,
  .markdown ol,
  .markdown blockquote {
    
  }

  .markdown h1 {
    @apply text-4xl !leading-[180%] font-bold;
  }
  
  .markdown h2 {
    @apply text-3xl !leading-[150%] font-bold;
  }
  
  .markdown h3 {
    @apply text-2xl !leading-[150%] font-medium;
  }
  
  .markdown a {
    @apply text-[#36067A]
  }

  .markdown a:hover {
    @apply underline
  }


}